
const icons = [
  'circle',
  'circle-alt',
  'dash',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'chevron-down',
  'chevron-dbl-left',
  'chevron-dbl-right',
  'chevron-dbl-up',
  'chevron-dbl-down',
  'bars',
  'dots-h',
  'dots-v',
  'sort',
  'sort-down',
  'sort-up',
  'search',
  'mail',
  'eye',
  'download',
  'link',
  'check',
  'check-circle',
  'info',
  'info-circle',
  'times',
  'times-circle',
  'warning',
  'warning-circle',
  'rss',
  'share',
  'facebook',
  'flickr',
  'instagram',
  'linkedin',
  'reddit',
  'snapchat',
  'tiktok',
  'twitter',
  'youtube',
  'vimeo'
]

module.exports = icons
