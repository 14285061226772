<template>
  <span
    aria-hidden="true"
    :class="[iconClass]"
    v-html="iconSrc"
  />
</template>

<script>
const iconNames = require('../assets/util/iconList').default
const buxIcons = require('../assets/util/buxIcons')

export default {
  props: {
    legacy: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'clipboard',
      validator (icon) {
        return [...iconNames, ...buxIcons].includes(icon)
      }
    }
  },
  computed: {
    isBux () {
      return this.legacy ? false : buxIcons.includes(this.icon)
    },
    iconClass () {
      return this.isBux ? `icon-${this.icon}` : 'icon'
    },
    iconSrc () {
      return this.isBux ? '' : require(`!html-loader!../assets/icons/${this.icon}.svg`)
    }
  }
}
</script>

<style lang="scss" scoped>
    .icon{
      @include inline-space($space-xs);
        /* Tie the icon height to the text size.*/
      height: 1em;
      width: 1em;
      line-height: 0;
      display: inline-block;
      & svg {
        /* Make SVGs take up the whole height */
        height: 100%;
        width: 1em;
        rect, circle, path, polygon {
          fill: currentColor;
        }
      }
    }
</style>

<docs>
  ```jsx
  const icons = require('../assets/util/iconList').default
  <BaseButton
    v-for="icon in icons"
    style="font-size:24px;"
    :icon="icon"
  >
  {{icon}}
  </BaseButton>
  ```
</docs>
