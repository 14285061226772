<template>
  <div
    :class="$style.nav"
  >
    <client-only>
      <nav
        aria-label="main navigation"
        v-show="isAuth"
        :class="{ [$style.homeNav] : isAuth }"
      >
        <ul id="main-nav-links">
          <li role="none">
            <NuxtNavLink
              to="/"
              class="mainNavLink"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Home
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/brand-guidelines"
              :match-exact="false"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Brand Guidelines
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/whats-new"
              :match-exact="false"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              What's New
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/community"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Community
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/questions"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              Questions
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/assets"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              <abbr title="Digital Asset Manager">DAM</abbr>
            </NuxtNavLink>
          </li>
          <li role="none">
            <NuxtNavLink
              class="mainNavLink"
              to="/search"
              @keydown.native="trapMenuFocus($event)"
              @click.native="$emit('changemenu', 'close')"
            >
              <client-only>
                <BaseIcon
                  :class="$style.search"
                  icon="search"
                />
                <span class="sr-only">Search</span>
              </client-only>
            </NuxtNavLink>
          </li>
        </ul>
      </nav>
    </client-only>
  </div>
</template>

<script>
import BaseIcon from '../components/BaseIcon.vue'
import NuxtNavLink from './NuxtNavLink.vue'
export default {
  components: {
    BaseIcon,
    NuxtNavLink
  },
  props: {
    toggleNav: {
      type: String,
      default: 'closed'
    },
    toggleMobile: Boolean
  },
  computed: {
    isAuth () {
      return this.$store.state.isAuth || this.$initAuth
    },
    setTabIndex () {
      if (this.toggleMobile) {
        return -1
      } else {
        return 0
      }
    }
  },
  data () {
    const domainApi = process.env.DRUPAL_AUTH_URL || 'https://dev-decoupler.pantheonsite.io/saml/login'
    return {
      loginUrl: `${domainApi}?l=${window.location.href}`
    }
  },
  watch: {
    toggleNav () {
      if (this.toggleNav === 'open') {
        this.$el.querySelector('#main-nav-links > li:first-child > a').setAttribute('tabindex', '0')
        // this.$el.querySelector('#main-nav-links > li:first-child > a').focus()
      }
    }
  },
  methods: {
    trapMenuFocus (e) {
      // for main nav menu links
      const osuNavLinks = document.querySelector('#main-nav-links')

      switch (e.key) {
        case 'Tab':
          // TAB KEY
          if (this.toggleMobile) {
            e.preventDefault()
            if (e.shiftKey) {
              if (e.currentTarget.parentElement.previousElementSibling !== null) {
                e.currentTarget.parentElement.previousElementSibling.querySelector('a').focus()
              } else {
                document.querySelector('#main-nav-trigger').focus()
              }
            } else if (e.currentTarget.parentElement.nextElementSibling !== null) {
              e.currentTarget.parentElement.nextElementSibling.querySelector('a').focus()
            } else {
              document.querySelector('#main-nav-trigger').focus()
            }
          }
          break
        case 'Enter':
        case ' ':
          // ENTER OR SPACEBAR KEY
          if (this.toggleMobile) {
            e.preventDefault()
            e.currentTarget.click()
            this.$emit('changemenu', 'close')
          }
          break
      }
    }
  }
}
</script>
<style lang="scss">
  #main-nav-links {
    abbr {
      text-decoration-line: none;
    }
  }
  .sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }
</style>
<style lang="scss" module>
  .search {
    font-size: 23px;
    padding: 0 0 0 8px;
    position: absolute;
    top: 12px;
    path {
      stroke-width: 1;
    }
  }

  .nav {
    @media (max-width:767px) {
      background: $color-gray-lightest;
      border-top: 1px solid $color-gray-lighter;
      padding: 0 16px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 96;
    }

    nav {
      height: 100%;
    }

    ul {
      @media (max-width:767px) {
        display: block;
        padding: 0 16px 16px;
      }
      display: flex;
      flex-flow: row wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: block;
      margin: 0 32px 0 0;
      padding: 0;

      @media (min-width:768px) {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .homeNav {
    ul {
      li {
        &:last-child {
          @media (min-width: 777px) {
            margin-left: auto;
          }
          a {
            color: $color-scarlet;
            height: 48px;
            width: 40px;
          }
        }
      }
    }
  }
</style>
