<template>
  <header
    :class="{ [$style.loginHeader] : !isAuth }"
  >
    <div id="osu-navbar" class="bux-osu-nav" role="navigation">
      <a href="#content" id="unav-skip"> Skip to main content</a>
      <h2 class="visually-hidden">
        Ohio State navigation bar
      </h2>
      <div class="bux-container container">
        <div id="osu-navname-block">
          <a class="bux-osu-nav__osu-logo-link" href="https://osu.edu">
            <img class="bux-osu-nav__osu-logo-img" src="/images/osu-navbar.svg" alt="The Ohio State University">
          </a>
        </div>
        <div
          id="osu-navlinks-block"
          @keydown.esc="closeOsuNav($event)"
        >
          <div
            class="osu-navlinks-overlay"
            @click="closeOsuNav($event)"
          />
          <button
            class="omc-menu-button"
            type="button"
            id="osu-nav-trigger"
            ref="osuNavTrigger"
            aria-controls="osu-navlinks"
            aria-expanded="false"
            @click="toggleOsuNav($event)"
            @keydown="keypressOpenOsuNav($event)"
          >
            <span class="visually-hidden">Show Links</span>
          </button>
          <ul id="osu-navlinks" aria-labelledby="osu-nav-trigger" class="bux-osu-nav__links">
            <li class="bux-osu-nav__link" role="none">
              <a href="https://www.osu.edu/map" @keydown="trapMenuFocus($event)">
                Map
              </a>
            </li>
            <li class="bux-osu-nav__link" role="none">
              <a href="http://buckeyelink.osu.edu/" @keydown="trapMenuFocus($event)">
                Buckeye Link
              </a>
            </li>
            <li class="bux-osu-nav__link" role="none">
              <a href="https://email.osu.edu/" @keydown="trapMenuFocus($event)">
                Webmail
              </a>
            </li>
            <li class="bux-osu-nav__link" role="none">
              <a href="https://www.osu.edu/search/" @keydown="trapMenuFocus($event)">
                Search Ohio State
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <div class="bux-container header" :class="$style.container">
        <h1 :class="$style.headerWrapper">
          <nuxt-link to="/" v-if="homePage" :class="$style.homeLink">
            <span :class="$style.brandCenter">
              Brand Center
            </span>
          </nuxt-link>
          <nuxt-link to="/" :class="$style.brandCenter" v-else>
            Brand Center
          </nuxt-link>
        </h1>
      </div>
      <div
        v-if="!$store.state.isStudent && ($route.name != 'external-brand-requests')"
        :class="$style.topNavWrapper"
      >
        <div class="bux-container header" :class="$style.container">
          <div
            v-show="!showMenu"
            class="main-nav-overlay"
            @click="closeMainNav($event)"
          />
          <button
            id="main-nav-trigger"
            ref="hamburgerButton"
            type="button"
            :class="[$style.navButton, { [$style.hideNav] : !isAuth }]"
            aria-controls="main-nav-links-region"
            :aria-expanded="!showMenu"
            @click="toggleMenuNav($event)"
            @keydown="trapMainMenuFocus($event)"
          >
            <span class="BaseIcon__icon BaseButton__buttonText" v-if="showMenu" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>menu_hamburger</title>
                <rect x="4" y="11" width="16" height="2" fill="#666" />
                <rect x="4" y="15" width="16" height="2" fill="#666" />
                <rect x="4" y="7" width="16" height="2" fill="#666" />
              </svg>
            </span>
            <span class="BaseIcon__icon BaseButton__buttonText" v-show="!showMenu" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <title>close</title>
                <polygon points="18.71 6.71 17.29 5.29 12 10.59 6.71 5.29 5.29 6.71 10.59 12 5.29 17.29 6.71 18.71 12 13.41 17.29 18.71 18.71 17.29 13.41 12 18.71 6.71" fill="#666" />
              </svg>
            </span>
            <span class="visually-hidden">
              Toggle main menu
            </span>
          </button>
          <client-only>
            <MainNav
              id="main-nav-links-region"
              :class="{ 'hidden' : showMenu }"
              :toggle-nav="toggleMainNav"
              :toggle-mobile="isMobile"
              aria-labelledby="main-nav-trigger"
              @keydown.esc.native="closeMainNav($event)"
              @changemenu="changeMenuNav($event)"
            />
          </client-only>
        </div>
      </div>
      <div class="student-border" v-else />
    </div>
  </header>
</template>

<script>
import MainNav from './MainNav.vue'
export default {
  components: {
    MainNav
  },
  data () {
    const domainApi = process.env.DRUPAL_AUTH_URL || 'https://dev-decoupler.pantheonsite.io/saml/login'
    return {
      loginUrl: process.client ? `${domainApi}?l=${window.location.href}` : domainApi,
      showMenu: true,
      isMobileOsuNav: false,
      isMobile: '',
      mobileOsuNavState: '',
      mobileState: '',
      toggleMainNav: 'closed'
    }
  },
  mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  computed: {
    homePage () {
      if (this.$route.path === '/') {
        return true
      } else {
        return false
      }
    },
    isAuth () {
      return this.$store.state.isAuth || this.$initAuth
    }
  },
  methods: {
    handleSkipToContent (e) {
      const target = document.querySelector('#content')
      const y = target.getBoundingClientRect().top + window.pageYOffset
      target.setAttribute('tabindex', '-1')
      window.scrollTo(0, y)
      target.focus()
    },
    async handleResize (e) {
      await this.$nextTick()
      // check mobile width for OSU utility nav links
      const currentMobileOsuNavState = this.mobileOsuNavState
      const osuNavTrigger = this.$refs.osuNavTrigger
      if (window.getComputedStyle(osuNavTrigger, null).getPropertyValue('display') === 'none') {
        this.isMobileOsuNav = false
        this.mobileOsuNavState = 'desktop'
      } else {
        this.isMobileOsuNav = true
        this.mobileOsuNavState = 'mobile'
        this.closeOsuNav()
      }
      // check mobile width for main menu nav links
      const currentMobileState = this.mobileState
      const hamburgerButton = this.$refs.hamburgerButton
      if (hamburgerButton && window.getComputedStyle(hamburgerButton, null).getPropertyValue('display') === 'none') {
        this.showMenu = false
        this.isMobile = false
        this.toggleMainNav = 'closed'
        this.mobileState = 'desktop'
      } else {
        this.showMenu = true
        this.isMobile = true
        this.mobileState = 'mobile'
      }
    },
    changeMenuNav (e) {
      if (this.isMobile) {
        this.showMenu = e
        this.toggleMainNav = 'closed'
      }
    },
    toggleMenuNav (e) {
      if (this.isMobile) {
        this.showMenu = !this.showMenu
        if (this.toggleMainNav === 'closed') {
          this.toggleMainNav = 'open'
        } else {
          this.toggleMainNav = 'closed'
        }
      }
    },
    toggleOsuNav (e) {
      const osuNavButton = e.currentTarget
      const osuNavOverlay = this.$el.querySelector('.osu-navlinks-overlay')
      if (osuNavButton.getAttribute('aria-expanded') === 'false') {
        osuNavButton.setAttribute('aria-expanded', 'true')
        osuNavOverlay.style.display = 'block'
      } else {
        osuNavButton.setAttribute('aria-expanded', 'false')
        osuNavOverlay.style.display = 'none'
      }
    },
    keypressOpenOsuNav (e) {
      const navButton = e.currentTarget
      const osuNavOverlay = this.$el.querySelector('.osu-navlinks-overlay')
      const osuNavLinks = this.$el.querySelectorAll('.bux-osu-nav__links > li > a')

      if ((e.shiftKey && e.keyCode === 9) && (this.isMobileOsuNav) && (navButton.getAttribute('aria-expanded') === 'true')) {
        e.preventDefault()
        osuNavLinks[osuNavLinks.length - 1].focus()
      } else if (e.keyCode === 13) {
        e.preventDefault()

        if (navButton.getAttribute('aria-expanded') === 'false') {
          // open dropdown menu and set focus to first link
          navButton.setAttribute('aria-expanded', 'true')
          osuNavOverlay.style.display = 'block'
        } else {
          this.closeOsuNav()
        }
      }
    },
    closeOsuNav (e) {
      const osuNavButton = this.$el.querySelector('.omc-menu-button')
      const osuNavOverlay = this.$el.querySelector('.osu-navlinks-overlay')
      osuNavButton.setAttribute('aria-expanded', 'false')
      osuNavButton.focus()
      osuNavOverlay.style.display = 'none'
    },
    closeMainNav (e) {
      this.showMenu = true
      this.toggleMainNav = ''
      this.toggleMainNav = 'closed'
      document.querySelector('#main-nav-trigger').focus()
    },
    trapMenuFocus (e) {
      // for osu utility nav links
      const osuNavButton = this.$el.querySelector('.omc-menu-button')
      const osuNavLinks = this.$el.querySelectorAll('.bux-osu-nav__links > li > a')

      switch (e.keyCode) {
        case 9:
          // TAB KEY
          if ((this.isMobileOsuNav) && (e.target === osuNavLinks[osuNavLinks.length - 1]) && !e.shiftKey) {
            e.preventDefault()
            osuNavButton.focus()
          }
          break

        default:
          break
      }
    },
    trapMainMenuFocus (e) {
      // for main nav menu links
      const mainNavButton = document.querySelector('#main-nav-trigger')
      const osuNavLinks = document.querySelector('#main-nav-links')

      if (mainNavButton.getAttribute('aria-expanded') === 'true') {
        switch (e.key) {
          case 'Tab':
            // TAB KEY
            e.preventDefault()
            if (e.shiftKey) {
              osuNavLinks.querySelector('li:last-child > a').focus()
            } else {
              osuNavLinks.querySelector('li:first-child > a').focus()
            }
            break
          case 'Enter':
          case ' ':
            // ENTER OR SPACEBAR KEY
            e.preventDefault()
            e.currentTarget.click()
            break
          case 'Escape':
            this.closeMainNav()
        }
      }
    }
  }
}
</script>

<style lang="scss" >
@import '@/assets/splash-page.scss';
#unav-skip {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0,0,0,0);
  position: absolute;
  border: 0;
}
#unav-skip:focus {
  height: 53px;
  line-height: 53px;
  width: 300px;
  clip: auto;
  background: #b00;
  z-index: 2400;
  text-align: center;
  outline: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 0;
  margin: 0 0 0 -150px;
  top: 0;
  color: #fff;
  left: 50%;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.7)
}
.osu-navlinks-overlay {
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 98;
}
.main-nav-overlay {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 95;
  @media(min-width: 768px) {
    display: none;
  }
}
#osu-nav-trigger {
  display: block;
}
#osu-nav-trigger[aria-expanded=true]:before {
  content: '';
}
#osu-nav-trigger[aria-expanded=true] {
  margin-top: 0;
  margin-right: 16px;
}
#osu-navlinks-block button {
  background: none;
  border: none;
  border-left: 2px solid #bfc6cb;
  cursor: pointer;
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  position: relative;
  right: -12px;
  z-index: 99;
  text-align: center;
  padding-top: 0;
  padding-left: 14px;
  position: relative;
  z-index: 100;

  &:after {
    font-family: bux-icons;
    content: "\f00e";
    color: #212325;
    font-size: 20px;
    cursor: pointer;
    font-weight: 700;
  }
  @media(min-width: 1280px) {
    display: none;
  }
}
#osu-nav-trigger[aria-expanded="true"] ~ #osu-navlinks {
  display: block;
  background-color: #fff;
  padding: 0 1.25rem .75rem;
  left: 0;
  width: 100%;
  margin: 54px 0 0 0;
  top: 0;
  position: absolute;
  z-index: 99;
}
.bux-osu-nav__link a:hover,
.bux-osu-nav__link a:focus {
  @media (max-width: 1279px) {
    color: #ba0c2f !important;
    border-left: 4px solid #3f4443;
    padding-left: 8px;
  }
}

 $gray-light-60: #dfe3e5 !default;
  .student-border {
    border-bottom: solid 2px $gray-light-60;
  }
.omc-menu-button {
  &:focus {
    outline: 2px solid #3492b8;
    outline-offset: 2px;
  }
}
h1 {
  margin-bottom: 0;
}
.hidden {
  display: none;
}
</style>

<style lang="scss" module>
  header {
    font-family: $font-heading;
    position: relative;
    z-index: 100;

    &.loginHeader {
      border-bottom: 2px solid #dfe3e5;
    }
  }

  .topHeader {
    border-bottom: 5px solid #b00;

    .container {
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .topNavWrapper {
    background: $color-gray-lightest;
    position: relative;

    .container {
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .navButton {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
    padding: 0;

    svg {
      color: $color-gray-dark;
      height: 45px;
      width: 45px;
    }

    @media (max-width: 767px) {
      /*border-left: 1px solid $color-gray-lighter;*/
      display: block;
      height: 47px;
      margin: 0 16px 0 0;
      position: absolute;
      right: 1px;
      top: -75px;
      width: 45px;
      z-index: 96;
    }
  }
  .hideNav {
    display: none;
  }
  .brandCenter {
    color: $color-black;
    display: inline-block;
    font-family: "BuckeyeSans", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.2;
    margin: 0;
    padding: 32px 50px 32px 0;
    text-decoration: none;
  }
  .center {
    text-align: center;
    color: $color-scarlet;
  }

  .homeLink {
    text-decoration: none;
  }
</style>
